// StateTaxRatesFront.js

const StateTaxRatesFront = {
  Alabama: 0.0,
  Arizona: 0.0,
  Arkansas: 0.0,
  California: 0.075,
  Colorado: 0.0,
  Connecticut: 0.0,
  Delaware: 0.0,
  "District of Columbia": 0.0,
  Florida: 0.0,
  Georgia: 0.0,
  Idaho: 0.0,
  Illinois: 0.0,
  Indiana: 0.0,
  Iowa: 0.0,
  Kansas: 0.0,
  Kentucky: 0.0,
  Louisiana: 0.0,
  Maine: 0.0,
  Maryland: 0.0,
  Massachusetts: 0.0,
  Michigan: 0.0,
  Minnesota: 0.0,
  Mississippi: 0.0,
  Missouri: 0.0,
  Montana: 0.0,
  Nebraska: 0.0,
  Nevada: 0.0,
  "New Hampshire": 0.0,
  "New Jersey": 0.0,
  "New Mexico": 0.0,
  "New York": 0.0,
  "North Carolina": 0.0,
  "North Dakota": 0.0,
  Ohio: 0.0,
  Oklahoma: 0.0,
  Oregon: 0.0,
  Pennsylvania: 0.0,
  "Rhode Island": 0.0,
  "South Carolina": 0.0,
  "South Dakota": 0.0,
  Tennessee: 0.0,
  Texas: 0.0,
  Utah: 0.0,
  Vermont: 0.0,
  Virginia: 0.0,
  Washington: 0.0,
  "West Virginia": 0.0,
  Wisconsin: 0.0,
  Wyoming: 0.0,
};

export default StateTaxRatesFront;
